<template>
  <div class="affiliateRevenuePage">
    <div class="container container-fluid">
      <div class="bar-back">
        <router-link to="/member/main"
          ><i class="fas fa-chevron-left"></i> หน้าหลัก</router-link
        >
      </div>
      <div
        class="p-2 w-100 bg-light main-content align-self-stretch"
        style="min-height: calc((100vh - 140px) - 50px)"
      >
        <div
          class="
            bgwhitealpha
            text-secondary
            shadow-sm
            rounded
            p-1
            px-2
            xtarget
            col-lotto
            d-flex
            flex-row
            mb-1
            pb-0
          "
        >
          <div class="lotto-title">
            <h4><i class="fas fa-thumbs-up"></i> แนะนำเพื่อน</h4>
          </div>
        </div>

        <div class="row px-0 mb-1 mx-0">
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate"
              class="
                btn-af btn btn-outline-primary btn-block
                d-flex
                flex-column
              "
            >
              <div class="affiliate-icon">
                <i class="far fa-handshake" />
              </div>
              ภาพรวม
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/members"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-chalkboard-teacher" />
              </div>
              ที่สมัคร
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/revenue"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column active"
            >
              <div class="affiliate-icon">
                <i class="fas fa-money-check-alt" />
              </div>
              รายได้
            </router-link>
          </div>
          <div class="col-3 p-1 pb-0 d-flex">
            <router-link
              to="/member/affiliate/withdraw"
              class="btn-af btn btn-outline-primary btn-block d-flex flex-column"
            >
              <div class="affiliate-icon">
                <i class="fas fa-hand-holding-usd" />
              </div>
              ถอนรายได้
            </router-link>
          </div>
        </div>
        <div class="w-100 my-2 border-bottom"></div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 mb-1 xtarget col-lotto">
            <h4 class=""><i class="fas fa-money-check"></i> รายได้</h4>
            <div class="w-100 my-2 border-bottom"></div>
          <b-row>
             <b-col cols="12"><label class="p-1 m-0"><i class="fas fa-calendar-alt"></i> วันที่ เริ่ม-สิ้นสุด</label>
             <small class="text-danger">(ข้อมูลจะแสดงไม่เกินย้อนหลัง 7 วัน)</small>
             </b-col>
            <b-col cols="12" md=4 style="padding-bottom:5px" @click="getAffiliate()">
              <b-form-datepicker
              v-model="date_from"
              id="datepicker-from"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              locale="th"
              placeholder="จากวันที่" size="sm"></b-form-datepicker>
            </b-col>
            <b-col cols="12" md=4 style="padding-bottom:5px" @click="getAffiliate()">
              <b-form-datepicker
              v-model="date_to"
              id="datepicker-to"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              locale="th"
              placeholder="ถึงวันที่" size="sm" ></b-form-datepicker>
            </b-col>
            <!-- <b-col cols="12" md=4 style="padding-bottom:5px">
              <b-button variant="primary" size="sm" ><i class="fas fa-search" ></i> ค้นหา</b-button>
            </b-col> -->
          </b-row>
        </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2  xtarget col-lotto">
        <b-row>
          <b-col>
            <div class="table-responsive">
              <table class="table table-sm table-hover table-bordered table-vcenter">
                <thead class="thead-light">
                  <tr class="text-center">
                    <th scope="col" style="width: 40%;">ประเภทเกม (ส่วนแบ่ง %)</th>
                    <th scope="col" style="width: 30%;">ยอดแทงสมาชิก</th>
                    <th scope="col" style="width: 30%;">รายได้</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="gamesAffiliateData.af_credit_to_credit_stagement.length">
                  <tr v-for="(item, index) in gamesAffiliateData.af_credit_to_credit_stagement" :key="index">
                    <td>หวยออนไลน์ ({{ item.af_share }}%)</td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(item.price ? item.price : 0).toFixed(2)) }}</td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(item.af_credit_request_to_move ? item.af_credit_request_to_move : 0).toFixed(2)) }}</td>
                  </tr>
                  </template>
                  <template v-else>
                  <tr>
                  <td>หวยออนไลน์ ({{ gamesAffiliateData.market_share_casino}}%)</td>
                  <td class="td-right">0.00</td>
                  <td class="td-right">0.00</td>
                  </tr>
                  </template>
                  <tr>
                    <td>คาสิโนออนไลน์ ({{ gamesAffiliateData.marketing_share_casino }}%)</td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(gamesAffiliateData.afCasinoTypeCasinoBet ? gamesAffiliateData.afCasinoTypeCasinoBet : 0).toFixed(2)) }}</td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(gamesAffiliateData.afCasinoTypeCasino ? gamesAffiliateData.afCasinoTypeCasino : 0).toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td>สล็อตเกม ({{ gamesAffiliateData.marketing_share_slot }}%)</td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(gamesAffiliateData.afCasinoTypeSlotBet ? gamesAffiliateData.afCasinoTypeSlotBet : 0).toFixed(2)) }}</td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(gamesAffiliateData.afCasinoTypeSlot ? gamesAffiliateData.afCasinoTypeSlot : 0).toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td>บอล/กีฬา ออนไลน์ ({{ gamesAffiliateData.marketing_share_sport }}%)</td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(gamesAffiliateData.afCasinoTypeSportBet ? gamesAffiliateData.afCasinoTypeSportBet : 0).toFixed(2)) }}</td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(gamesAffiliateData.afCasinoTypeSport ? gamesAffiliateData.afCasinoTypeSport : 0).toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><b>ยอดรวม</b></td>
                    <td class="td-right">{{ numberWithCommas(parseFloat(gamesAffiliateData.sumProfit ? gamesAffiliateData.sumProfit : 0).toFixed(2)) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
        </div>
        <!-- <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 mb-5 xtarget col-lotto" v-if="stagementIncome != null">
          <p class="alert alert-primary text-center">
              รายได้ ระบบแนะนำ จะถอนเข้าเป็นเงินเครดิต หากสงสัยโปรดติดต่อเอเย่นต์ที่ท่านสมัครสมาชิก                    </p>
            <div class="d-flex flex-row mb-2 w-100" v-for="(item, index) in stagementIncome" :key="index">
              <div class="table-secondary border rounded-left p-2 d-inline w-50 mr-0">{{ item.Date }}</div>
              <div class="bg-white border rounded-right text-right text-sm-right text-md-left p-2 d-inline w-50 ml-0"><span class="thb">฿ {{ numberWithCommas(item.Amount) }}</span></div>
            </div>
            <div class="d-flex flex-row mb-2 w-100">
              <div class="table-success border border-success rounded-left p-2 d-inline w-50 mr-0">ยอดรวม (เดือน)</div>
              <div class="bg-white border border-success border-left-0 rounded-right text-right text-sm-right text-md-left text-success p-2 d-inline w-50 ml-0"><span class="thb">฿ {{ numberWithCommas(totalMonth) }}</span></div>
            </div>
        </div> -->
        <!-- <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 mb-5 xtarget col-lotto" v-if="stagementIncome == null">
          <p class="alert alert-primary text-center">
            ไม่พบรายการ
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  async mounted () {
    if (process.env.VUE_APP_CASINO_ENABLE.toLowerCase().trim() === 'false') {
      this.$router.push('/member')
    }
    try {
      const today = new Date()
      this.date_from = (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate())
      this.date_to = (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate())
      await this.getAffiliate()
    } catch (error) {
    }
  },
  computed: {
    ...mapGetters([
      'getGames'
    ])
  },
  methods: {
    ...mapActions(['getGamesAffiliate']),
    numberWithCommas (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getAffiliate () {
      const resp = await this.getGamesAffiliate({
        date: {
          from: this.date_from,
          to: this.date_to
        }
      })
      // const today = new Date()
      // console.log('resp.status_code:' + resp.KINGMAKER)
      this.gamesAffiliateData = resp
      // console.log('resp:' + resp)
      // console.log('today:' + (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()))
    }
  },
  data () {
    return {
      date_from: '',
      date_to: '',
      gamesAffiliateData: []
    }
  }
}
// import {
//   inquiryIncomeAffiliateService,
//   inquiryBalanceAffiliateService
// } from '@/services'
// import { mapActions } from 'vuex'
// // import { MonthPickerInput } from 'vue-month-picker'
// // import VueMonthlyPicker from 'vue-monthly-picker'
// // import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
// export default {
//   components: {
//     // VueTimepicker,
//     // VueMonthlyPicker
//   },
//   async created () {
//     this.setLoading(true)
//     const resp = await inquiryIncomeAffiliateService()
//     if (resp.status_code === 'SUCCESS') {
//       this.totalIncomeBalance = resp.data.totalIncomeBalance
//     }
//     this.setLoading(false)
//   },
//   data () {
//     return {
//       totalIncomeBalance: '0.0',
//       date: this.$moment().format('MM/YYYY'),
//       monthText: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
//       selectedMonth: null,
//       stagementIncome: null,
//       totalMonth: 0
//     }
//   },
//   methods: {
//     ...mapActions(['setLoading']),
//     monthTick () {
//     },
//     numberWithCommas (n) {
//       // const x = Number(n)
//       // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
//       // return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(n)
//       var numeral = require('numeral')
//       return numeral(n).format('0,0.00')
//     }
//   },
//   watch: {
//     selectedMonth: async function (val) {
//       const mountYear = val._i.replace('/', '-')
//       const resp = await inquiryBalanceAffiliateService(mountYear)
//       if (resp.status_code === 'SUCCESS') {
//         if (resp.data.AfIncomeList === 'ไม่พบรายการ') {
//           this.stagementIncome = null
//         } else {
//           this.stagementIncome = resp.data.AfIncomeList
//           this.totalMonth = resp.data.totalMonth
//         }
//       }
//     }
//   }
// }
</script>

<style>
.text-red {
  color: red;
}
.text-green {
  color: green;
}
.td-right{
  text-align: right;
}
.td-center{
  text-align: center;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

/* .table {
  table-layout: fixed;
  width: 100%;
  font-size: 0.7rem;
} */

.table-responsive {
  overflow-x: auto;
}
.bg_tran{
    background-color: white;
}
</style>
